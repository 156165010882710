import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogPost/BlogPost.js";
import Code from '@components/Code';
import fakeNewsGradientCode from './code/fake-news-gradient.example';
import screenshot from './code/screenshot.example';
import pulumi from './code/pulumi.example';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In this tutorial i would like to demonstrate how to create a simple container-based AWS Lambda function and deploy it using Pulumi.`}</p>
    <h2>{`What is a container based AWS Lambda ?`}</h2>
    <p>{`You may already know the concept of Function-as-a-Service and that you don't need servers when writing Lambda functions. With Lambda, you write your function, upload it to AWS, and it's ready to be executed, No servers to manage.`}</p>
    <p>{`But the way we deployed our Lambda functions when Lambda was introduced back then in 2014 was by creating a ZIP archive from the code and it's dependencies and uploading it to AWS.`}</p>
    <p>{`Under the hood, AWS unzipped our function and executed it inside a docker container, but we didn't have any control of it, well, until now at least.`}</p>
    <blockquote>
      <p parentName="blockquote">{`AWS Lambda now supports packaging and deploying the lambda functions as container images.`}</p>
    </blockquote>
    <p>{`The best way to explain it will be by seeing this in action by creating a container-based lambda function in the following example.`}</p>
    <p>{`Our application will be a simple "screenshot" taker for a given website that you can define. In the example, we will take a screenshot of the front page of "hacker news" and upload the screenshot to AWS s3 on a daily basis.`}</p>
    <p>{`So we basically need to implement and write the code for the lambda function and package it inside the docker container.`}</p>
    <p>{`To deploy the container, we will first need to build the image and push it to a contaienr registry (we will use AWS ECR), and the last step will be to tell AWS Lambda itself to fetch the image that we just pushed from the repository and run it.`}</p>
    <p>{`I will use Pulumi for the steps mentioned above for the deployment, It will help us define all those actions we want to perform (build image, push image and so on) as infrastructure as code
and the best part of it is that we can choose our preferred language for it, we will use nodejs.`}</p>
    <p>{`Here are the stack and packages that we are going to use:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.pulumi.com"
        }}>{`Pulumi`}</a>{`, for deploying the lambda function as a container image.`}</li>
      <li parentName="ul">{`Pupeteer, for actually taking the screenshots.`}</li>
      <li parentName="ul">{`AWS s3 for storing the screenshots.`}</li>
    </ul>
    <h3>{`Dockerfile`}</h3>
    <p>{`our Docker file will be based on the base image `}<em parentName="p">{`public.ecr.aws/lambda/nodejs:14`}</em>{` as we need a nodejs base image that implements the AWS Lambda runtime API, so it's important to mentioned that
not every base image can be used to Lambda, AWS provides us with many base images that already include the runtime for most of the Languages out there, and even if you couldn't find
a base image for your needs, you can still take any image that you want and implement the AWS runtime API using a client SDK.`}</p>
    <p>{`Other then installing pupeteer and chrome-aws-lambda with npm there is not much going on here.
The code that we will use to take the screenshots lives inside `}<em parentName="p">{`app.js`}</em>{` and we execute it's handler when starting the container.`}</p>
    <Code lang="bash" mdxType="Code">{fakeNewsGradientCode}</Code>
    <h3>{`The Screenshot taker code app.js`}</h3>
    <Code lang="js" mdxType="Code">{screenshot}</Code>
    <p>{`We are doing the following in our code:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`we are launching pupeteer as a headless browser and visiting the website to take a screenshot.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`next, we are uploading the screenshot taken to s3 using aws-sdk that is available to us.`}</p>
      </li>
    </ul>
    <h3>{`lambda deployment as a container`}</h3>
    <p>{`now we have the functionality and we need to deploy the code as a lambda function and for that we can use pulumi with it's libraries that simplify the work for us.`}</p>
    <p>{`if you want to know more about pulumi visit their website `}<a parentName="p" {...{
        "href": "https://www.pulumi.com"
      }}>{`pulumi website`}</a>{` you will need to make sure that you have pulumi installed on your machine.`}</p>
    <p>{`Pulumi offers QuickStartes to allow us to start developing quickly and they offer a QuickStart for lambda as a container.`}</p>
    <p>{`To start the pulumi project, run:`}</p>
    <p><inlineCode parentName="p">{`pulumi new https://github.com/pulumi/apps/lambda-containers.`}</inlineCode></p>
    <p>{`move the Dockerfile we created to `}<inlineCode parentName="p">{`./app/dockerfile`}</inlineCode>{` and the app.js file to `}<inlineCode parentName="p">{`./app/app.js`}</inlineCode>{`.
That will be the lambda handler that will be executed when the container runs.`}</p>
    <p>{`In pulumi, you write you infrastructure as code, we will use typescript to define to the actions that we need. open the index.ts file in the root folder of the pulumi project and paste the following code:`}</p>
    <Code lang="typescript" mdxType="Code">{pulumi}</Code>
    <p>{`The following resources will be created if we will run our Pulmui code`}</p>
    <ul>
      <li parentName="ul">{`The docker image will be build and pushed to the ecr repository`}</li>
      <li parentName="ul">{`iam Role and policies for lambda permissions will be created`}</li>
      <li parentName="ul">{`the s3 bucket for saving the screenshots will be created`}</li>
      <li parentName="ul">{`The lambda function will be created and because we define imageUri property in the aws.lambda.Function method, the lambda will run the image we just created`}</li>
      <li parentName="ul">{`we also pass the bucket name as an environment variable to the functoin`}</li>
    </ul>
    <p>{`You can deploy the application with `}<inlineCode parentName="p">{`pulumi up`}</inlineCode></p>
    <p>{`Go to the AWS Lambda console and test if everything is working, you should se a screenshot file saved in the bucket.`}</p>
    <p>{`In the next part of the tutorial I will cover the topic of how to run lambda and how to setup a Schedule for the lambda function.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      